export const ProjectConfigOverride = {
  api_url: "https://pcapi.osabana.com",
  version: "2.1.2 (Development Build)",

  /*============================================
  SQUARE
  =============================================*/
  SQUARE_CLIENT_ID: "sandbox-sq0idb-UaKhFLJvaHZdzjOxFc9e3g",
  SQUARE_BASE_URL: "https://connect.squareupsandbox.com",
  SQUARE_REDIRECT_URI: "https://pcapi.osabana.com/square/auth",
};