import React, { useState } from 'react';
import { Button } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { IoCloseOutline } from "react-icons/io5";
import { FaRegFileAlt } from "react-icons/fa";

// Add this before the ImageUploaderProps interface
declare module 'react' {
    interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
        pc?: string;
    }
}

interface ImageUploaderProps {
    image: string;
    setImage: (image: string) => void;
    setFiles: (files: File[]) => void;
    className?: string;
    disabled?: boolean;
    maxFiles?: number; // Add maxFiles prop
}

export default function FileUploader({ 
    image, 
    setImage, 
    setFiles, 
    className, 
    disabled,
    maxFiles = 99 // Default to 99 if not specified
}: ImageUploaderProps) {
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [currentFiles, setCurrentFiles] = useState<File[]>([]);

    const formatFileName = (fileName: string) => {
        if (fileName.length <= 30) return fileName;
        const extension = fileName.split('.').pop();
        return `${fileName.substring(0, 30)}...${extension}`;
    };

    const handleFiles = (inputFiles: FileList | File[]) => {
        const newFilesArray = Array.from(inputFiles);
        const updatedFiles = [...currentFiles, ...newFilesArray];
        
        // Check if total files would exceed limit
        if (updatedFiles.length > maxFiles) {
            alert(`You can only upload a maximum of ${maxFiles} files.`);
            return;
        }
        
        setCurrentFiles(updatedFiles);
        setFiles(updatedFiles);
        
        // Update file names by appending new ones
        const newNames = newFilesArray.map(file => formatFileName(file.name));
        setFileNames(prev => [...prev, ...newNames]);
        
        // Set the first file's URL as the main preview if there wasn't one before
        if (!image && newFilesArray.length > 0) {
            setImage(URL.createObjectURL(newFilesArray[0]));
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            handleFiles(files);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            handleFiles(files);
        }
        e.currentTarget.classList.remove("dropzone_active");
    };

    const handleDelete = (index: number) => {
        // Remove file from arrays
        const updatedFiles = currentFiles.filter((_, i) => i !== index);
        const updatedNames = fileNames.filter((_, i) => i !== index);
        
        setCurrentFiles(updatedFiles);
        setFileNames(updatedNames);
        setFiles(updatedFiles);

        // Update main image if needed
        if (index === 0 && updatedFiles.length > 0) {
            setImage(URL.createObjectURL(updatedFiles[0]));
        } else if (updatedFiles.length === 0) {
            setImage('');
        }
    };

    return (
        <div className='files-uploader-container'
            onDrop={handleDrop}
            onDragOver={(e) => {
                e.preventDefault();
                e.currentTarget.classList.add("dropzone_active");
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.currentTarget.classList.remove("dropzone_active");
            }}
        >
            <div className="files-uploader-list-container" style={fileNames.length > 0  ? { display: 'flex' } : {display: 'none'}}>
                {fileNames.map((fileName, index) => (
                    <div key={index} className="files-uploader-name">
                        <span>{fileName}</span>
                        <IoCloseOutline 
                            className="delete-icon"
                            onClick={() => handleDelete(index)}
                        />
                    </div>
                ))}
            </div>
            <label id="files-uploader-drop-div" className={fileNames.length > 0  ? 'files-uploader-button-on-hover' : ''} style={disabled ? { display: 'none' } : {}}>
                <div className="fileUploaderButton">
                    <div className='files-uploader-button-label'><FaRegFileAlt /> Drag & Drop or Click to Upload</div>
                    <input
                        type="file"
                        pc="file"
                        multiple
                        hidden
                        onChange={handleFileChange}
                    />
                </div>
            </label>
        </div>
    );
}
