import axios from 'axios';
import {ProjectConfig} from 'Global';
import ReactDOMServer from 'react-dom/server';
/*=========================================================
API REQUEST
=========================================================*/
export const ApiRequest = async (params: any) => {

    const {
        url, 
        method, 
        headers, 
        body = null,
        query = null,
        formData = false,
        setResponse,
    } = params;

    // If formData is true, ensure we use FormData and set the correct header
    let data: any = null;
    if (formData && body instanceof FormData) {
        data = body;
        params.headers = {
            ...params.headers,
            'Content-Type': 'multipart/form-data'
        };
    } else {
        data = body ? body : null;
    }

    const request_params = {
        url: ProjectConfig.api_url+url,
        method: method,
        headers: {
            ...headers,
            domain: window.location.hostname,
            'ngrok-skip-browser-warning': 'true' 
        },
        params: {
            ...query
        },
        data
    }

    /*REQUEST
    =========================================================*/
    await axios.request(
        request_params
    )
    .then(async (response) => {
        if (process.env.NODE_ENV === 'development') {
            console.debug(`${url} response`,response.data);
        }
        await setResponse(response.data);
    })
    .catch((err) => {
        console.log("error",err)
    })
    .finally(() => {

    })
    

}

/*=========================================================
STOP WEBFLOW DEFAULT
=========================================================*/
export const StopWebflowDefault = () => {
    const handleSubmit = (event:any) => {
        event.preventDefault(); // Evita el comportamiento predeterminado
        event.stopPropagation(); // Detiene la propagación del evento
        const form = event.target;
    };

    // Añadir el event listener a todos los formularios con pc
    const forms = document.querySelectorAll("form[pc]");
    forms.forEach(form => {
        form.addEventListener("submit", handleSubmit);

        // Verifica si hay formularios internos dentro del formulario actual
        const internalForms = form.querySelectorAll("form");
        if (internalForms.length > 0) {
            internalForms.forEach(internalForm => {
                internalForm.addEventListener("submit", handleSubmit);
            });
        }
    });

    // Añadir el event listener a todos los formularios con pc-step
    const pcStep = document.querySelectorAll("[pc-step]");
    pcStep.forEach(container => {

        // Verifica si hay formularios internos dentro del formulario actual
        const internalForms = container.querySelectorAll("form");
        if (internalForms.length > 0) {
            internalForms.forEach(internalForm => {
                internalForm.addEventListener("submit", handleSubmit);
            });
        }
    });

    // Cleanup function to remove event listeners when el componente se desmonta
    return () => {
        forms.forEach(form => {
            form.removeEventListener("submit", handleSubmit);
        });
    };
}

/*=========================================================
BUILD LOADER
=========================================================*/
const BuildLoader = () => {

    


    const svg = ``;

    const loader = `<div id='penni_loader'>
        <div id="loader-container-inner" class="loader-container-inner">
            <div class="loader-line-mask">
                <div class="loader-line"></div>
            </div>
        </div>
    </div>`;
    
    //if not exists append loader
    if(!document.querySelector('#penni_loader')){
        document.body.insertAdjacentHTML('beforeend', loader);
    }
}

// export const ShowLoader = ( show: boolean ) => {
//     const loader = document.querySelector('#penni_loader');
//     if(loader){
//         if(show){
//             loader.classList.add('show_loader');
//         }else{
//             loader.classList.remove('show_loader');
//         }
//     }
// }



export const ShowLoader = ( show: boolean ) => {
    const loader = document.querySelector('#penni_loader');
    const skeletons = document.querySelectorAll('.skeleton');
    if(loader){
        if(show){
            loader.classList.add('show_loader');
        }else{
            loader.classList.remove('show_loader');
            skeletons.forEach(skeleton => {
                // skeleton.classList.remove('skeleton');
            });
        }
    }
}

/*=========================================================
GET COMPONENT AS SVG STRING
=========================================================*/
const defaultStyle = { 
    width: '16px', 
    height: '16px', 
    verticalAlign: 'middle', 
    marginRight: '4px' 
};

export const getComponentAsSvg = (props: any = {}) => {
    const { 
        component: Component, 
        style = defaultStyle 
    } = props;

    return ReactDOMServer.renderToString(<Component style={style} />);
};

export default {
    ApiRequest,
    StopWebflowDefault,
    BuildLoader,
    ShowLoader,
    getComponentAsSvg
}