import { ApiRequest, ShowLoader } from 'GlobalFunctions';
import { useEffect, useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import GenerateVariants from './Variants';
import getDataAttributes from './Attributes';
import { showBag, getProductQuantity } from 'components/Bag/Bag';
import FileUploader from './FileUploader';

// Keep roots map outside component to prevent recreation
const fileUploaderRoots = new Map();

export default function ProductUtils() {
    const [storeConfiguration, setStoreConfiguration] = useState<any>([]);
    const [fileUploads, setFileUploads] = useState<{ [key: string]: { files: File[] } }>({});
    const fileUploadsRef = useRef(fileUploads); // Add this line to keep a reference
    const isMounted = useRef(true);
    const [files, setFiles] = useState<File[]>([]); // Modified to explicitly type as File array
    const filesRef = useRef<File[]>([]);
    console.log("files", files);

    const [formValues, setFormValues] = useState({});
    
    // Update ref when state changes
    useEffect(() => {
        fileUploadsRef.current = fileUploads;
    }, [fileUploads]);

    // Add this effect to keep ref updated
    useEffect(() => {
        filesRef.current = files;
    }, [files]);

    useEffect(() => {
        // Set mounted flag
        isMounted.current = true;

        // Store roots for cleanup
        const roots = new Map();
        
        const selectors = [
            { attribute: "product-name" },
            { attribute: "product-price" },
            { attribute: "product-quantity" },
            { attribute: "product-sku" },
            { attribute: "product-image", target: "src" },
            { attribute: "tax-type" },
            { attribute: "weight" },
            { attribute: "category" },
            { attribute: "bulk-tiered-discounts" },
            { attribute: "v1-select" },
            { attribute: "v2-select" },
        ];

        /*=============================================
        GET ALL INPUTS VALUES INSIDE ADD TO CART FORM 
        =============================================*/
        const addToCartForm = document.querySelector("[pc='form']");

        const getCustomInputs = async (form: HTMLFormElement) => {
            // Get inputs from form elements excluding those with  attribute
            const customInputs = Array.from(form.elements)
                .filter((element: any) => !element.hasAttribute('pc'))
                .map((element: any) => {
                    let value;
                    if (element.type === 'checkbox') {
                        if (!element.checked) return null;
                        value = element.checked;
                    } else if (element.type === 'radio') {
                        if (element.checked) {
                            value = element.value;
                        } else {
                            return null;
                        }
                    } else {
                        value = element.value;
                    }
                    return {
                        name: element.name,
                        value: value
                    };
                })
                .filter(input => input !== null);
        
            // Capture elements with pc-custom attribute
            const pcCustomElements = Array.from(document.querySelectorAll('[pc-custom]'))
                .map((element) => {
                    const htmlElement = element as HTMLElement; // Ensure the element is treated as HTMLElement
                    const name = htmlElement.getAttribute('pc-custom');
                    const value = htmlElement.innerText.trim(); // The text inside the tag will be the value
                    return {
                        name: name,
                        value: value
                    };
                });
        
            const variantAttributes = getVariantAttributes();
        
            // Combine all inputs (form inputs + pc-custom elements + variant attributes)
            return [...customInputs, ...pcCustomElements, ...variantAttributes];
        };

        /*=============================================
        GET VARIANT ATTRIBUTES
        =============================================*/
        const getVariantAttributes = () => {
            const variantAttributes: { name: string, value: string }[] = [];
            const variantSelectors = ["v1-select", "v2-select"];

            variantSelectors.forEach((selector, index) => {
                const inputs = document.querySelectorAll(`[pc='${selector}']`) as NodeListOf<HTMLInputElement>;
                inputs.forEach((input, i) => {
                    const value = input.value;
                    const name = input.getAttribute('name') || `custom_${index + 1}_${i + 1}`;
                    variantAttributes.push({ name, value });
                });
            });

            return variantAttributes;
        }

        const validateVariants = () => {
            const variantSelectors = ["v1-select", "v2-select"];
            for (const selector of variantSelectors) {
                const inputs = document.querySelectorAll(`[pc='${selector}']`) as NodeListOf<HTMLInputElement>;
            }
            return true;
        }

        const addToCart = document.querySelector("[pc='add-btn']");
        if (addToCart) {
            addToCart.addEventListener("click", async () => {
                const requiredInputs = addToCartForm?.querySelectorAll("[required]") as NodeListOf<HTMLInputElement>;
                let allRequiredFilled = true;

                requiredInputs?.forEach(input => {
                    let isValid = true;
                    
                    if (input.type === 'checkbox' || input.type === 'radio') {
                        // Para checkbox y radio, verificar si alguno del mismo nombre está checked
                        const name = input.getAttribute('name');
                        const groupInputs = addToCartForm?.querySelectorAll(`[name="${name}"]`) as NodeListOf<HTMLInputElement>;
                        isValid = Array.from(groupInputs).some(inp => inp.checked);
                    } else {
                        // Para otros tipos de inputs, verificar el valor
                        isValid = input.value.trim() !== '';
                    }

                    if (!isValid) {
                        allRequiredFilled = false;
                        input.classList.add('error');
                    } else {
                        input.classList.remove('error');
                    }
                });

                if (!allRequiredFilled) {
                    return;
                }

                if (!validateVariants()) return;
                
                const productData = await getDataAttributes(selectors);
                if (addToCartForm) {
                    productData.custom_inputs = await getCustomInputs(addToCartForm as HTMLFormElement);
                }
                productData.order_id = localStorage.getItem('order_id');
                
                AddToCart(productData);
            });
        }

        // Modified file upload initialization
        const initializeFileUploader = () => {
            const fileElements = document.querySelectorAll("[pc='file']");
            fileElements.forEach((element, index) => {
                const uploaderId = `fileUploader_${index}`;
                if (fileUploaderRoots.has(uploaderId)) return;

                // Read the pc-upload attribute or default to 99
                const maxFiles = parseInt(element.getAttribute('pc-upload') || '99', 10);

                const fileContainer = document.createElement('div');
                fileContainer.className = 'pc-file-uploader-container';

                const handleFileChange = (files: File[]) => {
                    if (isMounted.current) {
                        setFileUploads(prev => {
                            const newState = {
                                ...prev,
                                [uploaderId]: { files }
                            };
                            console.log("ProductUtils - Setting new state:", newState);
                            return newState;
                        });
                    }
                };

                const root = createRoot(fileContainer);
                fileUploaderRoots.set(uploaderId, root);

                root.render(
                    <FileUploader 
                        className="account_logo"
                        image={""} 
                        setImage={(newImage) => setFormValues({ ...formValues, file: newImage })}
                        setFiles={setFiles}
                        maxFiles={maxFiles}
                    />
                );

                element.parentNode?.replaceChild(fileContainer, element);
            });
        };

        initializeFileUploader();
        
        // Updated cleanup function
        return () => {
            isMounted.current = false;
            // Delay unmounting to next tick to avoid React rendering conflicts
            setTimeout(() => {
                if (!isMounted.current) {
                    fileUploaderRoots.forEach((root, key) => {
                        try {
                            root.unmount();
                            fileUploaderRoots.delete(key);
                        } catch (error) {
                            console.warn('Error unmounting file uploader:', error);
                        }
                    });
                }
            }, 0);
        };

    }, []); // Remove fileUploads from dependency array

    GenerateVariants();

    useEffect(() => {
        handleQuantityInput()
    }, [storeConfiguration]);


    const handleQuantityInput = async () => {
        const quantityInput = document.querySelector("[pc='product-quantity']") as HTMLInputElement;
        const priceElement = document.querySelector("[pc='product-price']") as HTMLInputElement;
        const discountElement = document.querySelector("[pc='bulk-tiered-discounts']") as HTMLDivElement;
        // Verificar si el descuento "bulk_tiered_discounts" está activo en storeConfiguration usando `any` en `config`
        const bulkTieredDiscountConfig = storeConfiguration.find(
            (config: any) => config.logic === "bulk_tiered_discounts" && config.is_active
        );
    
        if (quantityInput) {
            let basePrice = priceElement ? parseFloat(priceElement.innerText) || 0 : 0;
    
            const discounts =
                bulkTieredDiscountConfig && discountElement
                    ? discountElement.innerText
                          .split(',')
                          .map((discount) => {
                              const [price, discount_quantity] = discount
                                  .split(':')
                                  .map((item) => parseFloat(item));
                              return { price, discount_quantity };
                          })
                          .sort((a, b) => a.discount_quantity - b.discount_quantity)
                    : []; // Ordenar por cantidad ascendente o mantener vacío
    
            const updatePriceBasedOnQuantity = (quantity: number) => {
                if (bulkTieredDiscountConfig && priceElement && discounts.length > 0) {
                    let newPrice = basePrice;
    
                    for (const discount of discounts) {
                        if (quantity >= discount.discount_quantity) {
                            newPrice = discount.price;
                        } else {
                            break;
                        }
                    }
    
                    priceElement.innerText = newPrice.toLocaleString('en-US', { minimumFractionDigits: 2 });
                }
            };
    
            const validateAndSetQuantity = () => {
                let value = parseInt(quantityInput.value);
                if (isNaN(value) || value < 1) {
                    quantityInput.value = '1';
                    value = 1;
                }
                updatePriceBasedOnQuantity(value);
            };
    
            quantityInput.addEventListener("input", () => {
                // Permitir edición libre sin validar inmediatamente
                const value = parseInt(quantityInput.value);
                if (!isNaN(value) && value >= 1) {
                    updatePriceBasedOnQuantity(value);
                }
            });
    
            quantityInput.addEventListener("blur", () => {
                // Validar y ajustar el valor cuando se pierde el foco
                validateAndSetQuantity();
            });
    
            // Validar el input al cargar la página
            validateAndSetQuantity();
        }
    };

    /*=========================================================
    CHECK BULK TIERED DISCOUNTS IS ACTIVE
    =========================================================*/
    useEffect(() => {
        getStoreConfiguration();
    }, []);
    const getStoreConfiguration = async () => {
        await ApiRequest({
            url: "/logic/storeConfiguration",
            method: "get",
            query: {
                order_id: localStorage.getItem('order_id'),
            },
            setResponse: (response: any) => {
                setStoreConfiguration(response);
            },
        });
    }

    /*=========================================================
    ADD TO CART
    =========================================================*/
    const AddToCart = async (productData: any) => {
        console.log("FILES", filesRef.current);
        console.log("AddToCart - Starting with productData:", productData);
        console.log("AddToCart - Starting with formValues:", formValues);

        const body = new FormData();
        body.append('formValues', JSON.stringify(productData));

        // Now filesRef.current is guaranteed to be an array
        if (filesRef.current && filesRef.current.length > 0) {
            console.log("AddToCart - Adding files to body:", filesRef.current);
            filesRef.current.forEach((file: File, index: number) => {
                body.append(`files`, file); // Changed to use 'files' as key
            });
        }

        // Remove this return statement that was preventing the API call
        
        ShowLoader(true);
        await ApiRequest({
            url: "/orders/addToCart",
            method: "post",
            a: true,
            body: body,
            setResponse: async (response: any) => {
                localStorage.setItem('order_id', response.data.order_id);
                await getProductQuantity();
                showBag();
            },
        });
    };

    return null;
}



